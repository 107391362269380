import React from "react";

export const renderMultiLine = (value: string) => {
  if (!value) return null;
  if (value.indexOf("\n") === -1) return value;
  const result: Array<React.ReactNode> = value.split("\n").map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));

  return result;
};
